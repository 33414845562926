// grid styleguides
@use 'mixins' as *;

.grid-auto {
  display: grid;
  gap: var(--gap, 1rem);
  grid-template-columns: 1fr;

  @include up('small') {
    grid-template-columns: repeat(auto-fill, minmax(var(--col-width, 5rem), 1fr));
  }
}

.grid-col {
  display: grid;
  gap: var(--gap, 1rem);
  grid-template-columns: repeat(var(--columns, 1), 1fr);
}

.grid-4 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.25rem;

  @include up('medium') {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @include up('large') {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.grid-3 {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.25rem;

  @include up('medium') {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include up('large') {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
