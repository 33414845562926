@use '../../assets/styles/mixins' as *;

.reason-card {
  display: flex;
  flex-direction: column;

  .top-container {
    background-color: var(--color-blue-dark);
    padding: 2.5rem 0.5rem 1.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .icon {
      margin-bottom: 1.25rem;
      color: var(--color-turquoise);
      font-size: 5rem;
    }

    span {
      color: var(--color-white);
      font-size: 1.5rem;
      font-weight: 700;
      text-align: center;
    }
  }

  .bottom-container {
    padding: 1rem 1.25rem;
    border: 1px dashed var(--color-blue-dark);
    border-top: 0;
    flex: 1 1 100%;
  }

  @include up('large') {
    .top-container {
      padding: 2.5rem 1.5rem;
    }
  }
}
