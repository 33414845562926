@use '../../assets/styles/mixins' as *;

$className: 'show-more';

.#{$className} {
  &__content {
    overflow: hidden;
    height: 0;

    &.--images {
      display: grid;
      gap: rem(4px);
      grid-template-columns: 1fr 1fr;
      margin-bottom: rem(8px);

      @include up('small') {
        gap: 1rem;
        margin-bottom: 1rem;
      }

      .show-more__item {
        img {
          width: 100% !important;
          height: 100% !important;
          object-fit: cover !important;
        }
      }
    }
  }

  &__button {
    display: inline-block !important;
  }

  &.-has-gradient {
    .#{$className} {
      &__button-container {
        position: relative;
        text-align: center;
        margin-top: 1rem;

        &::before {
          content: '';
          position: absolute;
          width: 100%;
          height: rem(126px);
          bottom: rem(70px);
          left: 0;
          background: url('../images/show-more-gradient.png') repeat-x;
        }

        &.opened {
          margin-top: 1rem;

          @include up('small') {
            margin-top: 1.5rem;
          }

          &::before {
            display: none;
          }
        }
      }
    }
  }
}

.show-more-icons {
  .grid-auto {
    & > div {
      text-align: center;

      img {
        width: 4.5rem;
        aspect-ratio: 1;
      }

      p {
        font-size: rem(14px);
        line-height: 1rem;
        margin-top: 0.5rem;
      }
    }
  }
}
