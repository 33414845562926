@use '../../../assets/styles/mixins' as *;

input[type='text'],
input[type='email'],
input[type='password'],
input[type='number'],
input[type='date'],
input[type='tel'] {
  appearance: none;
  border: rem(1px) solid var(--color-blue-dark);
  border-radius: 0.25rem;
  padding: 1rem 0.5rem;
  color: var(--color-blue-dark);
  font-size: rem(14px);
  font-weight: 400;
  text-align: left;
  width: 100%;
  height: auto;
  background: var(--color-white);
  transition: all var(--transition-duration) var(--easing);

  &::placeholder {
    opacity: 1;
    color: var(--color-blue-dark);
  }

  &:focus {
    border: rem(1px) solid var(--color-turquoise);
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    background: var(--color-secondary-light);
    opacity: 0.5;

    @media (hover: hover) {
      &:hover {
        box-shadow: none;
      }
    }

    &:focus {
      box-shadow: none;
    }
  }

  .-has-error & {
    border: rem(1px) solid var(--color-red);

    &:focus {
      border: rem(1px) solid var(--color-turquoise);
    }
  }

  @media (hover: hover) {
    &:hover {
      transition: box-shadow var(--transition-duration) var(--easing);
      box-shadow: var(--box-shadow);
    }
  }
}
