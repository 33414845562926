@use 'mixins' as *;

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  overflow: hidden;
  fill: currentColor;
  stroke: none;
  pointer-events: none;
}

.hide-mobile {
  display: none;

  @include up('medium') {
    display: inline-block;
  }
}

.hide-desktop {
  display: inline-block;

  @include up('medium') {
    display: none;
  }
}

.hidden {
  display: none !important;
}

.section-title {
  position: relative;
  padding-top: 2.5rem;

  span {
    background: var(--color-peach);
  }

  &::before {
    content: '';
    width: 4rem;
    height: 1px;
    background: var(--color-blue-dark);
    top: 0;
    left: 0;
    position: absolute;
  }

  @include up('large') {
    padding-top: 0;
    padding-left: rem(110px);

    &::before {
      top: rem(42px);
    }
  }
}
