@use 'sass:color';
@use '../../assets/styles/mixins' as *;

.modal {
  &__overlay {
    display: none;
    place-items: center;
    position: fixed;
    inset: 0;
    padding: 0 1rem;
    z-index: 10;
    background: color.adjust(#111, $alpha: -0.4);

    &[aria-hidden='false'] {
      display: grid;
    }

    &[aria-hidden='true'] {
      display: none;
    }
  }

  &__content {
    position: relative;
    background: var(--color-white);
    border-radius: rem(8px);
    width: 100%;
    max-width: rem(600px);
    max-height: 85vh;
    padding: 2.5rem 2rem 2rem;
    overflow-x: hidden;
    overflow-y: auto;

    picture,
    img {
      width: 100%;
    }

    & *:last-child {
      margin-bottom: 0;
    }

    &.-clean {
      padding: 0;
      max-width: rem(900px);
    }
  }

  &__close {
    position: absolute;
    width: 3rem;
    aspect-ratio: 1;
    top: 0;
    right: 0;
    color: var(--color-black);
    background: var(--color-white);
    border-radius: 0 0 0 50%;
    display: grid;
    place-items: center;
    cursor: pointer;

    .icon {
      font-size: 1rem;
    }
  }
}
