@use '../../assets/styles/mixins' as *;

.img-text-card {
  padding-bottom: 2.5rem;

  .image {
    margin-bottom: 2.5rem;

    img {
      width: 100%;
    }
  }

  .content {
    .button {
      width: 100%;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  h3 {
    color: var(--color-turquoise);
    margin-bottom: 1.25rem;
  }

  @include up('medium') {
    display: grid;
    grid-template-columns: 0.75fr 1fr;
    gap: rem(85px);
    padding-bottom: rem(120px);

    &.inverted {
      grid-template-columns: 1fr 0.75fr;

      .content {
        order: 1;
      }

      .image {
        order: 2;
      }
    }

    .content {
      .button {
        width: auto;
      }
    }
  }
}
