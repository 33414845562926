@use '../../assets/styles/mixins' as *;

$className: 'pagination';

.#{$className} {
  text-align: center;

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.3rem;
    margin-bottom: 1.5rem;

    @include up('medium') {
      gap: 1rem;
    }
  }

  &__desc {
    font-size: rem(12px);
    color: var(--color-secondary-dark);
    margin-bottom: 0;
  }

  &__pages {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.2rem;

    @include up('medium') {
      gap: 0.75rem;
    }
  }

  &__item {
    width: 2rem;
    aspect-ratio: 1;
    display: grid;
    place-items: center;
    border-radius: 50%;
    text-decoration: none;
    flex-shrink: 0;

    @media (hover: hover) {
      &:hover {
        color: var(--color-black);
        background-color: var(--color-primary-light);
      }
    }

    &--prev,
    &--next {
      width: 2rem;
      border: rem(1.2px) solid var(--color-secondary);
      transition: background-color var(--transition-duration) var(--easing);

      .icon {
        font-size: rem(12px);
        transform: rotate(-90deg);
      }

      @media (hover: hover) {
        &:hover {
          background-color: var(--color-primary-light);
        }
      }
    }

    &--prev {
      .icon {
        transform: rotate(90deg);
      }
    }

    &--active {
      background-color: var(--color-primary);
      color: var(--color-white);
      cursor: default;
      width: 2rem;

      @media (hover: hover) {
        &:hover {
          background-color: var(--color-primary);
          color: var(--color-white);
        }
      }
    }

    &--dots {
      width: 1.5rem;

      @media (hover: hover) {
        &:hover {
          color: unset;
          background-color: unset;
        }
      }
    }

    @media (max-width: em(1023px)) {
      .#{$className}__pages & {
        &:nth-child(n + 4):not(:last-child):not(.#{$className}__item--dots) {
          display: none;
        }
      }
    }
  }
}
