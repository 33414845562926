@use 'sass:list';
@use 'sass:map';
@use 'sass:string';
@use 'sass:math';

$extend: ();
$screens: () !default;
$extend: $screens;

@function get-value($token) {
  @return map.get($extend, $token);
}

@function get-next($value) {
  $keys: map.keys($extend);
  $n: list.index($keys, $value);

  @return if($n < length($keys), list.nth($keys, $n + 1), null);
}

@function set-min($token) {
  $min: get-value($token);

  @return if($min != 0, $min, null);
}

@function set-max($token) {
  $max: get-value($token);

  @return if($max and $max > 0, $max - 1, null);
}

@function em($px) {
  @return math.div($px, 16px) * 1em;
}

@mixin up($token) {
  @if $token and not map.has-key($extend, $token) {
    @error "Invalid token, `#{$token}` doesn't exist. Choose one of #{map.keys($extend)}.";
  }

  $min: set-min($token);

  @if $min {
    @media (min-width: em($min)) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin down($token) {
  @if $token and not map.has-key($extend, $token) {
    @error "Invalid token, `#{$token}` doesn't exist. Choose one of #{map.keys($extend)}.";
  }

  $max: set-max($token);

  @if $max {
    @media (max-width: em($max)) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin only($token) {
  @if $token and not map.has-key($extend, $token) {
    @error "Invalid token, `#{$token}` doesn't exist. Choose one of #{map.keys($extend)}.";
  }

  $min: set-min($token);
  $next: get-next($token);
  $max: set-max($next);

  @if $min != null and $max != null {
    @media (min-width: em($min)) and (max-width: em($max)) {
      @content;
    }
  } @else if $max == null {
    @include up($token) {
      @content;
    }
  }
}

@mixin between($min, $max) {
  @if $min and not map.has-key($extend, $min) {
    @error "Invalid token, `#{$min}` doesn't exist. Choose one of #{map.keys($extend)}.";
  } @else if $max and not map.has-key($extend, $max) {
    @error "Invalid token, `#{$max}` doesn't exist. Choose one of #{map.keys($extend)}.";
  }

  $min: set-min($min);
  $max: set-max($max);

  @if $min != null and $max != null {
    @media (min-width: em($min)) and (max-width: em($max)) {
      @content;
    }
  }
}
