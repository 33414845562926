@use '../../assets/styles/mixins' as *;

$className: 'footer';

.#{$className} {
  color: var(--color-turquoise);
  padding: 2.5rem;
  background: var(--color-blue-dark);

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }

  &__copy {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 2.5rem;
  }

  &__social {
    ul {
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      list-style: none;

      li {
        margin: 0;
        padding: 0 1rem;
      }
    }
  }

  a {
    text-decoration: underline;
    color: var(--color-turquoise);

    @media (hover: hover) {
      &:hover {
        text-decoration: none;
      }
    }
  }

  @include up('large') {
    padding: rem(60px) 2.5rem rem(54px);

    .container {
      flex-direction: row;
    }

    &__copy {
      flex-direction: row;
      margin-bottom: 0;
    }
  }
}
