@use 'mixins' as *;

$className: 'styleguide';

body.#{$className} {
  // stylguide styles
  main {
    padding-bottom: 2rem;
  }

  .#{$className} {
    &-header {
      position: relative;
      background: var(--color-primary);
      padding: 1rem 0;

      .container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo {
          display: block;

          img {
            width: rem(250px);
          }
        }

        h4 {
          margin-bottom: 0;
          color: var(--color-white);
        }
      }
    }

    &-footer {
      color: var(--color-white);
      padding: 1rem 0;
      background: var(--color-primary);

      .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &__copy {
        text-align: center;
      }

      a {
        color: var(--color-white);
        @media (hover: hover) {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    &-title {
      color: var(--color-secondary);
    }

    &-icons {
      --col-width: 10rem;

      &__icon {
        padding: 1rem;
        border: rem(1px) solid var(--color-secondary-light);
        border-radius: var(--border-radius);
        text-align: center;

        .icon {
          font-size: 2rem;
          color: var(--color-primary);
        }

        p {
          margin: 1rem 0 0;
        }
      }
    }

    &-colors {
      --col-width: 10rem;

      .color {
        padding: 1rem;
        border-radius: var(--border-radius);
        text-align: center;

        p {
          margin-bottom: 0;

          &:last-child {
            font-weight: 600;
            text-transform: uppercase;
          }
        }

        &.-white-text {
          color: var(--color-white);
        }

        &.-with-border {
          border: rem(1px) solid rgba(0 0 0 / 0.08);
        }
      }
    }

    &-forms {
      --columns: 1;

      margin-bottom: var(--spacing-400);

      @include up('medium') {
        --columns: 2;
        --gap: 2rem;
      }
    }

    &-single-image-slider {
      max-width: rem(400px);
      transition: max-width var(--transition-duration) var(--easing);

      @include up('medium') {
        max-width: rem(600px);
      }
    }

    &-height {
      max-height: 20rem;
    }

    &-tooltips {
      --col-width: 8rem;

      .square {
        border-radius: var(--border-radius);
        padding: 1rem;
        font-size: 600;
        color: var(--color-white);
        text-align: center;

        p {
          margin-bottom: 0;
        }

        &.-blue {
          background-color: var(--color-primary);
        }

        &.-red {
          background-color: var(--color-error);
        }

        &.-green {
          background-color: var(--color-success);
        }

        &.-orange {
          background-color: var(--color-warning);
        }
      }
    }

    &-flex {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

.styleguide-footer-link a {
  color: var(--color-white);
  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
}
