@use '../../assets/styles/mixins' as *;

.keyvisual {
  background-color: var(--color-blue-dark);
  background-image: url('../images/keyvisual-bg.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 100%;
  position: relative;
  padding-top: 2.5rem;
  padding-bottom: 4rem;

  &::before {
    content: '';
    background: var(--color-blue-dark);
    opacity: 0.8;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 1;
  }

  .keyvisual-container {
    position: relative;
    z-index: 2;
  }

  .title {
    h1 {
      color: var(--color-white);
      text-align: center;

      span {
        background-color: var(--color-peach);
        color: var(--color-blue-dark);
      }
    }
  }

  .content {
    color: var(--color-white);

    .lead {
      text-align: center;
      margin-bottom: 1.25rem;
    }

    p {
      margin-bottom: 2.5rem;
      color: var(--color-grey);
    }

    .button {
      width: 100%;
      margin-bottom: 1rem;
    }
  }

  .scroll-down {
    width: rem(60px);
    height: rem(60px);
    margin: 1.5rem auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    z-index: 2;
  }

  @include up('medium') {
    .content {
      .button-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
      }
    }
  }

  @include up('large') {
    background-position: left center;
    background-size: cover;
    padding-top: rem(102px);
    padding-bottom: 2.5rem;

    .keyvisual-container {
      display: grid;
      grid-template-columns: 1fr;
      gap: rem(60px);
      align-items: center;
    }
  }
}
