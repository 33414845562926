@use '../../assets/styles/mixins' as *;

$className: 'swiper';

.#{$className} {
  padding-bottom: 5rem;

  @include up('medium') {
    padding-bottom: 3.5rem;
  }

  &-slide {
    img {
      border-radius: var(--border-radius);
    }
  }

  &-pagination-bullet {
    width: 0.5rem;
    height: 0.5rem;
    margin: 0 0.5rem !important;
    background: var(--color-primary-light);
    opacity: 1;

    &-active {
      background: var(--color-primary);
    }
  }

  &-button-next,
  &-button-prev {
    top: calc(50% - 1.5rem);
    background: var(--color-white);
    width: 2.5rem;
    height: 2.5rem;
    padding: 0;
    border-radius: 50px;
    border: rem(2px) solid var(--color-secondary-light);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: var(--color-black);
    transition: background-color var(--transition-duration) var(--easing);

    @media (hover: hover) {
      &:hover {
        background-color: var(--color-primary);
        color: var(--color-white);
      }
    }

    .icon {
      font-size: rem(18px);
    }

    &.swiper-button-disabled {
      display: none;
    }
  }

  &-button-next {
    right: rem(5px);

    .icon {
      transform: rotate(-90deg);
    }
  }

  &-button-prev {
    left: rem(5px);

    .icon {
      transform: rotate(90deg);
    }
  }
}
