@use 'vars';
@use 'mixins' as *;

@include spacings;

body {
  font-family: var(--font-averta);
  display: flex;
  flex-direction: column;
  line-height: 1.5;
  font-size: 1rem;
}

main {
  flex-grow: 1;
}

.container {
  max-width: var(--container-width);
  margin: 0 auto;
  padding: 0 var(--content-padding);
  width: 100%;
}

.container-inner {
  max-width: rem(895px);
  margin: 0 auto;
}

.overlay {
  background: var(--color-black);
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 2;
}

.dark-bg {
  background: var(--color-blue-dark);
  color: var(--color-white);

  .section-title {
    color: var(--color-turquoise);

    span {
      color: var(--color-blue-dark);
    }

    &::before {
      background: var(--color-turquoise);
    }
  }
}

.block {
  display: block;
}

.button-container-middle {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  margin-top: 2.5rem;

  .button {
    width: 100%;
    margin-bottom: 0;
  }

  @include up('large') {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0;

    .button {
      width: auto;
      margin-right: 1.25rem;
      margin-left: 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
