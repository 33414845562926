@use '../../assets/styles/mixins' as *;

$className: 'header';

.#{$className} {
  position: relative;
  background: var(--color-blue-dark);
  padding: rem(27px) 1.25rem;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      display: block;

      img {
        width: rem(145px);
      }
    }
  }

  .language-select {
    color: var(--color-white);

    a {
      color: var(--color-white);
      transition: color var(--transition-duration) var(--easing);
      padding: 0 0.25rem;

      &.active {
        text-decoration: underline;
      }

      @media (hover: hover) {
        &:hover {
          color: var(--color-turquoise);
        }
      }
    }
  }

  @include up('large') {
    padding: 2.5rem 1.25rem;

    .container {
      .logo {
        img {
          width: rem(266px);
        }
      }
    }
  }
}
