@use '../../assets/styles/mixins' as *;

.icon-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: rem(20px) rem(34.5px) rem(36px);
  border: 1px dashed var(--color-blue-dark);

  .icon {
    background: var(--color-turquoise);
    color: var(--color-blue-dark);
    font-size: 6rem;
    margin-bottom: 1.25rem;
  }

  span {
    text-align: center;
    font-weight: 700;
  }
}
