@use '../../assets/styles/mixins' as *;

.countdown {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2.5rem;

  .single-countdown {
    margin-left: 1.5rem;

    .number {
      width: rem(63px);
      height: rem(63px);
      border: 0.6px solid var(--color-turquoise);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: rem(35px);
      line-height: rem(54px);
      font-weight: 700;
      aspect-ratio: 1;
    }

    span {
      display: block;
      text-align: center;
      font-weight: 700;
      text-transform: uppercase;
      font-size: rem(12.21px);
      line-height: rem(17.09px);
      margin-top: rem(13px);
    }

    &:first-child {
      margin-left: 0;
    }
  }

  @include up('large') {
    justify-content: space-between;

    .single-countdown {
      .number {
        border: 1px solid var(--color-turquoise);
        width: rem(104px);
        height: rem(104px);
        font-size: rem(58px);
        line-height: rem(87px);
      }

      span {
        margin-top: rem(22px);
        font-size: 1.25rem;
        line-height: rem(28px);
      }
    }
  }
}
