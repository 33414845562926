@use '../../assets/styles/mixins' as *;

.brands-container {
  .section-title {
    padding-top: 0;

    &::before {
      content: none;
    }
  }

  @include up('large') {
    display: grid;
    grid-template-columns: 1fr 0.7fr;
    gap: 4rem;

    .section-title {
      order: 2;
      padding-left: 0;
    }

    .brands {
      order: 1;
    }
  }
}

.brands {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2.5rem;
  justify-content: center;
  align-items: center;

  @include up('medium') {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @include up('large') {
    display: grid;
    gap: 2.5rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
