@use 'mixins' as m;

:root {
  // colors - change this to match figma!!
  --color-white: #fff;
  --color-black: #000;
  --color-red: #d01;
  --color-turquoise: #87ebda;
  --color-peach: #ffcfbe;
  --color-blue: #034ad4;
  --color-blue-light: #cddbf6;
  --color-blue-dark: #031f51;
  --color-grey: #d9d9d9;
  --color-error: #ee2f3f;
  --color-warning: #ffcb5c;
  --color-success: #31af59;

  // sizes https://utopia.fyi/type/calculator/?c=360,16,1.2,1440,22,1.25,5,1,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l
  // --size-50: clamp(0.83rem, calc(0.78rem + 0.25vw), 1rem);
  // --size-000: clamp(1rem, calc(0.92rem + 0.37vw), 1.25rem);
  // --size-100: clamp(1.2rem, calc(1.08rem + 0.54vw), 1.56rem);
  // --size-200: clamp(1.44rem, calc(1.27rem + 0.76vw), 1.95rem);
  // --size-300: clamp(1.73rem, calc(1.49rem + 1.06vw), 2.44rem);
  // --size-400: clamp(2.07rem, calc(1.75rem + 1.45vw), 3.05rem);
  // --size-500: clamp(2.49rem, calc(2.05rem + 1.97vw), 3.82rem);

  // spacings https://utopia.fyi/space/calculator/?c=360,8,1.2,1440,16,1.25,5,2,&s=0.5,1.5|2|2.5|3|3.5|4|4.5|5,
  --spacing-50: clamp(0.25rem, calc(0.17rem + 0.37vw), 0.5rem);
  --spacing-000: clamp(0.5rem, calc(0.33rem + 0.74vw), 1rem);
  --spacing-100: clamp(0.75rem, calc(0.5rem + 1.11vw), 1.5rem);
  --spacing-200: clamp(1rem, calc(0.67rem + 1.48vw), 2rem);
  --spacing-300: clamp(1.25rem, calc(0.83rem + 1.85vw), 2.5rem);
  --spacing-400: clamp(1.5rem, calc(1rem + 2.22vw), 3rem);
  --spacing-500: clamp(1.75rem, calc(1.17rem + 2.59vw), 3.5rem);
  --spacing-600: clamp(2rem, calc(1.33rem + 2.96vw), 4rem);
  --spacing-700: clamp(2.25rem, calc(1.5rem + 3.33vw), 4.5rem);
  --spacing-800: clamp(2.5rem, calc(1.67rem + 3.7vw), 5rem);
  --spacing-900: clamp(3.5rem, calc(2.33rem + 5.19vw), 7rem);

  // font weights
  --fw-50: 300;
  --fw-000: 400;
  --fw-100: 500;
  --fw-200: 600;
  --fw-300: 700;

  // transitions
  --easing: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  --transition-duration: 0.2s;

  // shadows
  --box-shadow: 0.25rem 0.25rem 0.75rem 0 rgb(0 0 0 / 0.15);
  --box-shadow-down: 0.25rem 0 0.75rem 0 rgb(0 0 0 / 0.15);

  // general
  --container-width: 77rem; // 1440px
  --content-padding: 1rem;
  --border-radius: 0.5rem;
  --font-sans: 'Open Sans', sans-serif;
  --font-averta: 'Averta', Helvetica, Arial, sans-serif;
}
