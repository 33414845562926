@use '../../assets/styles/mixins' as *;

$className: 'counter';

.counters-container {
  --gap: 30px;
  --columns: 1;

  @include up('small') {
    --columns: 2;
  }

  @include up('medium') {
    --columns: 4;
  }

  .#{$className} {
    text-align: center;

    &__number {
      margin-bottom: 1rem;
      white-space: nowrap;

      &-number,
      &-prefix,
      &-suffix {
        display: inline-block;
        margin-bottom: 0;
        font-size: 4rem;
        font-weight: 500;
      }
    }

    &__title {
      margin-bottom: 0;
      white-space: nowrap;
    }
  }
}
