/* stylelint-disable font-family-name-quotes */
// Open Sans
@font-face {
  font-family: 'Averta';
  src: url('../fonts/averta/Averta-Regular.woff2') format('woff2'), url('../fonts/averta/Averta-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Averta';
  src: url('../fonts/averta/Averta-Semibold.woff2') format('woff2'), url('../fonts/averta/Averta-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Averta';
  src: url('../fonts/averta/Averta-Bold.woff2') format('woff2'), url('../fonts/averta/Averta-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
