/**
 *
 * Typography
 *
 */

@use 'vars';
@use 'mixins' as *;

.light {
  font-weight: var(--fw-50) !important;
}

.regular {
  font-weight: var(--fw-000) !important;
}

.medium {
  font-weight: var(--fw-100) !important;
}

.semibold {
  font-weight: var(--fw-200) !important;
}

.bold,
strong {
  font-weight: var(--fw-300) !important;
}

h1,
.h1 {
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 1.5;
  margin: 0 0 var(--spacing-300);

  @include up('large') {
    font-size: 4rem;
  }
}

h2,
.h2 {
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.5;
  margin: 0 0 var(--spacing-300);

  @include up('large') {
    font-size: 3.5rem;
  }
}

h3,
.h3 {
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 1.5;
  margin: 0 0 var(--spacing-300);
}

h4,
.h4 {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.5;
  margin: 0 0 var(--spacing-300);
}

p {
  font-size: 1rem;
  font-weight: 400;
  margin: 0 0 1.5em;
}

.lead {
  font-size: 1.25rem;
}

.small,
small {
  font-size: rem(14px);
}

.center {
  text-align: center;
}

.uppercase {
  text-transform: uppercase;
}

blockquote {
  padding-left: 3rem;
  position: relative;
  font-size: rem(20px);
  line-height: rem(32px);
  margin-bottom: 1.5em;

  &::before {
    content: '';
    height: 100%;
    width: 4px;
    border-radius: var(--border-radius);
    background-color: var(--color-primary);
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translate(0, -50%);
  }
}

ul {
  padding: 0 0 0 1rem;
  margin-bottom: 1.5em;

  li {
    margin-bottom: 1em;

    &::marker {
      color: var(--color-primary);
    }
  }
}
