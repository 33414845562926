@use '../../assets/styles/mixins' as *;

.faq-card {
  background: var(--color-blue-light);
  margin-bottom: rem(30px);
  padding: rem(10px);

  h3 {
    margin-bottom: rem(30px);
  }

  p {
    margin-bottom: rem(9px);
  }

  .sub-card {
    margin-bottom: 1.5rem;

    &:last-child {
      margin-bottom: 0;
    }

    .title {
      background: var(--color-blue-dark);
      padding: rem(13px) rem(20px);

      h4 {
        margin: 0;
        color: var(--color-white);
      }
    }

    .desc {
      padding: rem(26px) rem(10px);
      border: 1px dashed var(--color-blue-dark);
      border-top: 0;

      p:last-child {
        margin: 0;
      }
    }
  }

  @include up('large') {
    margin-bottom: rem(20px);
    padding: rem(20px);

    h3 {
      margin-bottom: 2rem;
    }

    p {
      margin-bottom: rem(13px);
    }

    .sub-card-wrap {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: rem(20px);
    }

    .sub-card {
      margin-bottom: 0;
      display: flex;
      flex-direction: column;

      .desc {
        padding: rem(20px);
        flex: 1 1 100%;
      }
    }
  }
}
