@use '../../assets/styles/mixins' as *;

/**
 *
 * Button styles
 *
 */

$className: 'button';

/**
 *
 * Button config
 *
 */

$buttonPrimaryColor: var(--color-peach);
$buttonSecondaryColor: var(--color-blue-dark);
$buttonTerciaryColor: var(--color-turquoise);
$buttonBorderRadius: rem(2px);

// Box model
$buttonMargin: 1.5rem;
$buttonPadding: rem(12px) rem(60px);

%button {
  appearance: none;
  cursor: pointer;
  margin: 0 auto $buttonMargin;
  text-align: center;
  backface-visibility: hidden;
  border-radius: $buttonBorderRadius;
  border: rem(1px) solid $buttonPrimaryColor;
  padding: $buttonPadding;
  background-color: $buttonPrimaryColor;
  color: var(--color-white);
  text-decoration: none;
  transition: all var(--transition-duration) var(--easing);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;

  .icon {
    color: var(--color-white);
    width: 1rem;
    transition: all 0.25s ease;
    margin-right: 1rem;
  }

  &[disabled] {
    background-color: red;
    border-color: red;
    cursor: default;
  }
}

.#{$className} {
  @extend %button;

  &.peach {
    background-color: $buttonPrimaryColor;
    border: rem(1px) solid $buttonPrimaryColor;
    color: $buttonSecondaryColor;

    .icon {
      color: $buttonSecondaryColor;
    }

    @media (hover: hover) {
      &:hover {
        background: transparent;
        color: $buttonPrimaryColor;

        .icon {
          color: $buttonPrimaryColor;
        }
      }
    }

    &.outline {
      color: $buttonPrimaryColor;
      background: transparent;

      .icon {
        color: $buttonPrimaryColor;
      }

      @media (hover: hover) {
        &:hover {
          background: rgb(255 207 190 / 0.2);
        }
      }
    }
  }

  &.blue {
    background-color: $buttonSecondaryColor;
    border: rem(1px) solid $buttonSecondaryColor;
    color: var(--color-white);

    @media (hover: hover) {
      &:hover {
        background: transparent;
        color: $buttonSecondaryColor;

        .icon {
          color: $buttonSecondaryColor;
        }
      }
    }

    &.outline {
      color: $buttonSecondaryColor;
      background: transparent;

      .icon {
        color: $buttonSecondaryColor;
      }

      @media (hover: hover) {
        &:hover {
          background: rgb(3 31 81 / 0.2);
        }
      }
    }
  }

  &.turquoise {
    background-color: $buttonTerciaryColor;
    border: rem(1px) solid $buttonTerciaryColor;
    color: $buttonSecondaryColor;

    @media (hover: hover) {
      &:hover {
        background: transparent;
        color: $buttonTerciaryColor;

        .icon {
          color: $buttonTerciaryColor;
        }
      }
    }

    .icon {
      color: $buttonSecondaryColor;
    }

    &.outline {
      color: $buttonTerciaryColor;
      background: transparent;

      .icon {
        color: $buttonTerciaryColor;
      }

      @media (hover: hover) {
        &:hover {
          background: rgb(135 235 218 / 0.2);
        }
      }
    }
  }
}
