@use '../../assets/styles/mixins' as *;

table {
  min-width: 100%;
  margin-bottom: 2rem;

  caption {
    text-align: left;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.105em;
    margin-bottom: rem(10px);
    padding-left: 1rem;
  }

  thead {
    background-color: var(--color-secondary-light);

    tr {
      th {
        color: var(--color-secondary-dark);
        font-weight: 400;
        text-align: left;
        padding: rem(12px) 1rem;

        &:first-child {
          border-radius: 0.5rem 0 0 0.5rem;
        }

        &:last-child {
          border-radius: 0 0.5rem 0.5rem 0;
        }
      }
    }
  }

  tbody {
    tr {
      border-bottom: rem(1px) solid var(--color-secondary-light);

      td {
        padding: 1rem;
      }
    }
  }

  &.toc {
    margin-bottom: var(--spacing-600);
  }
}
