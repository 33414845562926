/**
 *
 * Mixins
 *
 */

@use 'sass:map';
@use 'sass:math';

// breakpoints - up, down, only, between (use the included 'rs-bp' snippet)
@forward './breakpoints' with (
  $screens: (
    'small': 480px,
    'medium': 768px,
    'large': 1024px,
    'xlarge': 1280px,
    'xxlarge': 1440px,
  )
);

// rem and em helpers
@function rem($px) {
  @return math.div($px, 16px) * 1rem;
}

@function em($px) {
  @return math.div($px, 16px) * 1em;
}

// Spacings
$spacings: '50', '000', '100', '200', '300', '400', '500', '600', '700', '800', '900';
$directions: 'top', 'left', 'right', 'bottom';
$properties: 'margin', 'padding';

@mixin spacings {
  @each $spacing in $spacings {
    @each $property in $properties {
      .#{$property}-#{$spacing} {
        #{$property}: var(--spacing-#{$spacing}) !important;
      }

      @each $direction in $directions {
        .#{$property}-#{$direction}-#{$spacing} {
          #{$property}-#{$direction}: var(--spacing-#{$spacing}) !important;
        }
      }
    }
  }

  @each $property in $properties {
    .no-#{$property} {
      #{$property}: 0 !important;
    }

    @each $direction in $directions {
      .no-#{$property}-#{$direction} {
        #{$property}-#{$direction}: 0 !important;
      }
    }
  }
}

@mixin aspect-ratio($w, $h) {
  aspect-ratio: #{$w} / #{$h};

  @supports not (aspect-ratio: #{$w} / #{$h}) {
    &::before {
      content: '';
      float: left;
      padding-top: math.div($h, $w) * 100%;
    }

    &::after {
      content: '';
      display: block;
      clear: both;
    }
  }
}
